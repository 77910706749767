// When the user scrolls the page, execute myFunction 
var lastScrollTop = 0;
var scrollState = 0;
var navbar = document.getElementById("site-header");

window.onscroll = function() {myFunction()};

// Get the navbar


// Get the offset position of the navbar
if(navbar)
var sticky = navbar.offsetTop;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
	var navbar = document.getElementById("site-header");
	if(!navbar) return;
	var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
   if (st > lastScrollTop){
      scrollState = -1;

   } else {
      scrollState = 1;

   }
   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

   if(window.innerWidth>768){
		if (window.pageYOffset >= sticky+20) {
			navbar.classList.add("sticky")
		} else {
			navbar.classList.remove("sticky");
		}

   }else{
		if (window.pageYOffset >= sticky+20 && scrollState==1) {
			navbar.classList.add("sticky")
		} else {
			navbar.classList.remove("sticky");
		}

   }
}